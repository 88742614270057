<template>
  <div>
    <v-card class="ma-0 rounded-xxl pb-4 elevation-5" height="auto" color="#fff">
      <v-card-text>
        <v-data-table
          dense
          :headers="headers"
          :items="itemLists"
          class="elevation-1 mb-5"
          :items-per-page="30"
          :footer-props="{
              'items-per-page-options': [10, 20, 30, 40, 50, -1]
          }"
          :loading="$store.state.overlay"
          height="440"
          fixed-header
          :divider="true"
          :light="true"
          :search="searchItem" 
          :item-class="tr_datatable"
      >    
          <template v-slot:top>
              <v-toolbar flat color="white" class="mt-2 mb-2">
                  <div class="d-flex w-100">
                      <v-text-field solo style="max-width: 300px;" class="border-12 mr-3 " v-model="searchItem" append-icon="mdi-magnify" label="Search" dense single-line hide-details></v-text-field>
                      <v-spacer></v-spacer>
                      <v-btn
                      small
                      color="#fff"
                      class="py-5 mr-3 hidden-sm-and-down"
                      @click="populateData()"
                      >
                          <v-icon>mdi-cached</v-icon>
                      </v-btn>
                      <v-btn
                      small
                      color="success"
                      class="py-5 mr-0"
                      @click="showDialogAbsenceRequest()"
                      v-if="aprv_level"
                      >
                      <v-icon>mdi-plus</v-icon>
                      </v-btn>
                  </div>
              </v-toolbar>
          </template>
          <template v-slot:[`item.file_name`]="{ item }">
              <v-btn text v-if="item.file_name == 'Y'" @click="showImage(item)" color="primary" small dark>view</v-btn>
          </template>
          <template v-slot:[`item.aprv`]="{ item }">
            <div v-if="item.dt_reject != '01-01-1900'">
              <v-btn text class="ma-1"  color="red" dense small dark>Rejected</v-btn>
            </div>
            <div v-else>
              <v-btn text class="ma-1" v-if="item.stat == 'C'" color="success" dense small dark>Approved</v-btn>
              <v-btn text class="ma-1"  v-if="item.stat == 'O'"  color="warning" dense small dark>Not Approved</v-btn>
              <v-icon text class="ma-1" v-if="item.stat == 'O' && aprv_level" color="error" @click="deleteAbsence(item.doc_id)">mdi-delete</v-icon>

            </div>
          </template>
          <template v-slot:[`item.stat`]="{ item }">
              <v-btn text class="ma-1" v-if="item.stat == 'O'" color="blue" dense small dark>OPEN</v-btn>
              <v-btn text class="ma-1" v-else-if="item.stat == 'C'" color="red" dense small dark>CLOSED</v-btn>
              <v-icon class="ma-1" v-if="item.stat == 'O'" color="error" @click="deleteAbsence(item.doc_id)">mdi-delete</v-icon>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
    <v-dialog
      v-model="dialogCreateRequest"
      persistent
      max-width="800px"
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <v-card class="rounded-l">
        <v-card-title class="text-center mb-0 pb-0">
            <v-row>
                <v-col cols="12">
                    <div class="d-flex">
                        <div class="ml-5">
                          New Overtime Order
                        </div>
                        <v-spacer></v-spacer>
                        <v-btn
                            class="mx-2"
                            fab
                            x-small
                            color="#e83e8c;"
                            @click="[dialogCreateRequest = false]"
                        >
                            <v-icon dark>
                                mdi-close
                            </v-icon>
                        </v-btn>
                    </div>
                </v-col>
                <v-divider></v-divider>
            </v-row>
        </v-card-title>
        <v-card-text class="ma-0">
          <v-container fluid class="ma-0">
            <v-row>
              <v-col class="col-12 ma-0 pa-1" sm="6" md="2">
                <span class="blue-lcd mb-1 font-12">Company </span>
                <v-autocomplete
                    dense
                    outlined
                    clearable
                    v-model="addCompanyIDModel"
                    :items="addCompanyIDList"
                    item-value="Company"
                    item-text="description"
                    class="ma-0 mt-1 pa-0"
                    hide-details=true
                    disabled
                ></v-autocomplete> 
              </v-col>
              <v-col class="col-12 ma-0 pa-1" sm="6" md="4">
                <span class="blue-lcd mb-1 font-12">Reason </span>
                <v-autocomplete
                    dense
                    outlined
                    clearable
                    v-model="addIjinModel"
                    :items="addIjinList"
                    item-value="str1"
                    item-text="str2"
                    class="ma-0 mt-1 pa-0"
                    hide-details=true
                ></v-autocomplete> 
              </v-col>
              <v-col class="col-12 ma-0 pa-1" sm="6" md="3">
                <span class="blue-lcd mb-1 font-12">Start Date </span>
                <v-dialog ref="dialog" v-model="dateStartModal" :return-value.sync="dateStartModel" persistent width="290px" >
                    <template v-slot:activator="{ on, attrs }">
                        <v-text-field outlined v-model="dateStartModel" readonly v-bind="attrs" v-on="on" dense clearable append-icon="mdi-calendar-check" class="ma-0 mt-1 pa-0" hide-details></v-text-field>
                    </template>
                    <v-date-picker v-model="dateStartModel" :show-current="true" @click:date="$refs.dialog.save(dateStartModel)" scrollable>
                        <v-spacer></v-spacer>
                        <v-btn text color="primary" @click="dateStartModal = false">Cancel</v-btn>
                    </v-date-picker>
                </v-dialog>
              </v-col>
              <v-col class="col-12 ma-0 pa-1" sm="6" md="3">
                <span class="blue-lcd mb-1 font-12">Start Time </span>
                <v-dialog
                  ref="dialogTime"
                  v-model="modal2"
                  :return-value.sync="time"
                  persistent
                  width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      dense
                      v-model="time"
                      prepend-inner-icon="mdi-clock-time-four-outline"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      outlined
                      class="ma-0 mt-1 pa-0"
                      hide-details
                    ></v-text-field>
                  </template>
                  <v-time-picker
                    v-if="modal2"
                    v-model="time"
                    format="24hr"
                    full-width
                    @click:hour="$refs.dialogTime.save(time)"
                  >
                    <v-spacer></v-spacer>
                    <v-btn
                      text
                      color="primary"
                      @click="modal2 = false"
                    >
                      Cancel
                    </v-btn>
                  </v-time-picker>
                </v-dialog>
              </v-col>
              <v-col class="col-12 ma-0 pa-1" sm="6" md="3">
                <span class="blue-lcd mb-1 font-12">End Date  </span>
                <v-dialog ref="dialog2" v-model="dateEndModal" :return-value.sync="dateEndModel" persistent width="290px">
                    <template v-slot:activator="{ on, attrs }">
                        <v-text-field outlined v-model="dateEndModel" readonly v-bind="attrs" v-on="on" dense clearable append-icon="mdi-calendar-check" class="ma-0 mt-1 pa-0" hide-details></v-text-field>
                    </template>
                    <v-date-picker v-model="dateEndModel" :show-current="true" @click:date="$refs.dialog2.save(dateEndModel)" scrollable>
                        <v-spacer></v-spacer>
                        <v-btn text color="primary" @click="dateEndModal = false">Cancel</v-btn>
                    </v-date-picker>
                </v-dialog>
              </v-col>
              <v-col class="col-12 ma-0 pa-1" sm="6" md="3">
                <span class="blue-lcd mb-1 font-12">End Time  </span>
                <v-dialog
                  ref="dialogTime2"
                  v-model="modalTime2"
                  :return-value.sync="time2"
                  persistent
                  width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      dense
                      v-model="time2"
                      prepend-inner-icon="mdi-clock-time-four-outline"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      outlined
                      class="ma-0 mt-1 pa-0"
                      hide-details
                    ></v-text-field>
                  </template>
                  <v-time-picker
                    v-if="modalTime2"
                    v-model="time2"
                    format="24hr"
                    full-width
                    @click:hour="$refs.dialogTime2.save(time2)"
                  >
                    <v-spacer></v-spacer>
                    <v-btn
                      text
                      color="primary"
                      @click="modalTime2 = false"
                    >
                      Cancel
                    </v-btn>
                  </v-time-picker>
                </v-dialog>
              </v-col>
              <v-col class="col-12 ma-0 pa-1" sm="6" md="6">
                <span class="blue-lcd mb-1 font-12">Remark  </span>
                <v-text-field outlined dense class="ma-0 mt-1 pa-0" v-model="addRemarkModel" hide-details></v-text-field>
              </v-col>
              <v-col class="col-12 ma-0 pa-1" sm="6" md="6">
                <span class="blue-lcd mb-1 font-12">Picture File</span>
                <v-file-input outlined class="ma-0 mt-1 pa-0" v-model="fileUpload" small-chips counter show-size append-icon="mdi-image" accept="image/*" dense></v-file-input>
              </v-col>
              <v-col class="col-12 ma-0 pa-1" sm="12" md="12">
                <span class="blue-lcd mb-1 font-12">List Overtime Employee</span>
                <v-simple-table
                  fixed-header  
                  height="300"  
                  dense                                                         
                >
                  <thead>
                    <tr style="border-top:1px solid rgb(216 212 212);">
                      <th class="text-center" style="width: 5%">
                        No
                      </th>
                      <th class="text-center" style="width: 30%">
                        ID Employee
                      </th>
                      <th class="text-center" style="width: 60%">
                        Name
                      </th>
                      <th style="width: 5%">

                      </th>
                    </tr>
                  </thead>
                  <tbody style="border-bottom:1px solid rgb(216 212 212);">
                    <tr v-for="(emp, index) in choice_employees" :key="index">
                      <td>{{ index + 1 }}</td>
                      <td>
                        <v-autocomplete
                            dense
                            outlined
                            clearable
                            v-model="emp.idemployee"
                            :items="employees"
                            item-value="idemployee"
                            :item-text="item => item.idemployee +' - '+ item.name"
                            class="ma-0 mt-2 pa-0"
                            hide-details=true
                            return-object
                            @change="(event) => getEmployeeID(event, index)"
                        ></v-autocomplete> 
                      </td>
                      <td>
                        <v-text-field outlined dense class="ma-0 mt-2 pa-0" v-model="emp.name" hide-details readonly></v-text-field>
                      </td>
                      <td>
                        <v-btn text small class="p-1 text-white" color="red" @click="deleteItem(emp, index)">
                            <v-icon>
                                mdi-delete
                            </v-icon>
                        </v-btn>
                      </td>
                    </tr>
                    <tr>
                      <td colspan="5">
                        <v-btn block class="ma-1" @click="addItem()"><v-icon class="mr-3">mdi-plus</v-icon> Add Employee</v-btn>
                      </td>
                    </tr>
                  </tbody>
                </v-simple-table>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions class="text-center">
            <v-row justify="center">
                <v-col cols="12" xs="12" sm="3" md="2">
                    <v-btn block class="rounded-l mr-2 p-2" color="#fff" @click="[dialogCreateRequest = false]">Cancel</v-btn>
                </v-col>
                <v-col cols="12" xs="12" sm="3" md="2">
                    <v-btn block class="rounded-l text-white p-2" color="success" @click="submitAbsenceRequest()">Save</v-btn>
                </v-col>
            </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
        v-model="dialogShowImage"
        persistent
        max-width="1000px"
        hide-overlay
        transition="dialog-bottom-transition"
    >
      <v-card class="rounded-l">
        <v-card-title class="text-center mb-0 pb-0">
            <v-row>
                <v-col cols="12">
                    <div class="d-flex">
                        <div class="ml-5">
                          List File
                        </div>
                        <v-spacer></v-spacer>
                        <v-btn
                            class="mx-2"
                            fab
                            x-small
                            color="#e83e8c;"
                            @click="[dialogShowImage = false]"
                        >
                            <v-icon dark>
                                mdi-close
                            </v-icon>
                        </v-btn>
                    </div>
                </v-col>
                <v-divider></v-divider>
            </v-row>
        </v-card-title>
        <v-card-text class="ma-0">
          <v-carousel cycle height="100%">
            <v-carousel-item
              v-for="(item,i) in imageList"
              :key="i"
              :src="item"
              reverse-transition="fade-transition"
              transition="fade-transition"
            ></v-carousel-item>
          </v-carousel>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import {user} from "@/backend-api/user"
import {overtime} from "@/backend-api/overtime"
import {employee} from "@/backend-api/employee"
import VueCropper from 'vue-cropperjs'
import 'cropperjs/dist/cropper.css'

export default {
  components:{
    VueCropper
  },
  props: ['tr_type', 'itemLists', 'aprv_level'],
  data: () => ({
    headers: [
        { text: 'DATE', value: 'tr_date', align: 'left', class: 'primary--text blue lighten-5' },
        { text: 'ID EMPLOYEE', value: 'idemployee', align: 'left', class: 'primary--text blue lighten-5' },
        { text: 'NAME', value: 'employee', align: 'left', class: 'primary--text blue lighten-5' },
        { text: 'REASON', value: 'ijin_description', align: 'left', class: 'primary--text blue lighten-5' },
        { text: 'ATTACHMENT', value: 'file_name', align: 'center', class: 'primary--text blue lighten-5' },
        { text: 'REMARK', value: 'keterangan', align: 'left', class: 'primary--text blue lighten-5' },
        { text: 'START TIME', value: 'dt_time_from', align: 'left', class: 'primary--text blue lighten-5' },
        { text: 'UNTIL TIME', value: 'dt_time_to', align: 'left', class: 'primary--text blue lighten-5' },
        { text: 'INTERVAL TIME', value: 'interval_hour', align: 'right', class: 'primary--text blue lighten-5' },
        { text: 'STAT APRV', value: 'aprv', align: 'left', class: 'primary--text blue lighten-5' },
        // { text: '', value: 'stat', align: 'center', class: 'primary--text blue lighten-5' },        
        // { text: '', value: 'actions', align: 'center', class: 'primary--text blue lighten-5' },
    ],
    dialogInfo: false,
    headersColumn:{
      'DATE': 'tr_date',
      'REASON': 'ijin_descr',
      'REMARK': 'keterangan',
      'START DATE': 'dt_from',
      'UNTIL DATE': 'dt_to',
      'Stat': 'stat',
    },
    searchItem: '',
    dialogCreateRequest: false,
    addIjinModel: '',
    dateStartModel: '',
    dateEndModel: '',
    addRemarkModel: '',
    fileUpload: '',
    addCompanyIDModel: '',
    addCompanyIDList: [],
    addIjinModel: '',
    addIjinList: [],
    addIjinLoading: false,
    dateStartModal: false,
    dateStartModel: '',
    dateStartFormatted: '',
    dateEndModal: false,
    dateEndModel: '',
    dateEndFormatted: '',
    addRemarkModel: '',
    // imageModel: '',
    imageModel: [],
    dialogShowImage: false,
    imageList: [],
    dialogfilter: false,
    status_aprv: '',
    status_aprvs: [
      {
        text: 'Disetujui',
        value: 'disetujui'
      },
      {
        text: 'Belum Disetujui',
        value: 'belum_disetujui'
      }
    ],
    max_aprv: 1,
    foto_profile: '',
    dialog_avatar: false,
    imgSrc: '',
    modal2: false,
    time: '00:00',
    time2: '00:00',
    modalTime2: false,
    employ: '',
    employees: [],
    employee_name: '',
    choice_employees: []
  }),
  methods:{
    tr_datatable(item) {
      var rowClass = 'tr_datatable'
      return rowClass;
    },
    clear(){
      this.addIjinModel = ''
      this.addRemarkModel = ''
      this.dateStartModel = ''
      this.dateEndModel = ''
      this.addIjinModel = ''
      this.fileUpload = ''
      this.choice_employees = []
    },
    async populateData(){
      this.$emit("displayData", this.tr_type);
    },
    showDialogAbsenceRequest(){
      this.dialogCreateRequest = true
      this.addIjinModel = ''
      this.dateStartModel = ''
      this.dateEndModel = ''
      this.addRemarkModel = ''
      this.fileUpload = ''
    },
    async initialize(){
      const respData = await user.fetchCompany(`?id=${this.$store.state.user.id}`, null, false, false, false) 
      if (respData.status === 200) {
        this.addCompanyIDModel = this.$store.state.user.employee.company_id
        this.addCompanyIDList = respData.data.entity
        this.addIjinList = respData.data.lembur
      }
    },
    async maxApprove(){
      var reqBody = {
        company: this.$store.state.user.employee.company_id.trim(),
        tr_type: this.tr_type,
        dep_id: this.$store.state.user.employee.dept_id ? this.$store.state.user.employee.dept_id.trim() : '',
        division_id: this.$store.state.user.employee.division_id ? this.$store.state.user.employee.division_id.trim() : ''
      }

      const respData = await user.maxApprove('', reqBody, false, false, false)

      if (respData.status === 200) {
        console.log(respData.data)
        this.max_aprv = respData.data.aprv_num ? Number(respData.data.aprv_num) : 1
        // console.log(this.max_aprv);
      }
    },
    datediff(first, second) {        
        return Math.round((second - first) / (1000 * 60 * 60 * 24));
    },
    parseDate(date) {
        var formattedDate = date.split("-")
        return new Date(formattedDate[0], formattedDate[1], formattedDate[2])
    },
    diff_hours(dt2, dt1){
      var diff =(dt2.getTime() - dt1.getTime()) / 1000;
      diff /= (60 * 60);
      return Math.abs(Math.round(diff));
      
    },
    async submitAbsenceRequest(){
      if(this.addIjinModel == null || this.addIjinModel == ''){
          Swal.fire({
                  text: 'Please Select Absence Reason !',
                  icon: 'warning',
              })
          return false
      }

      if(this.addRemarkModel == null || this.addRemarkModel == ''){

          Swal.fire({
                  text: 'Please Fill Absence Remark !',
                  icon: 'warning',
              })
          return false

      }

      if(this.dateStartModel == null || this.dateStartModel == '' || this.time == null || this.time == ''){

        Swal.fire({
                text: 'Please Fill Start Date and Time !',
                icon: 'warning',
            })
        return false

      }

      if(this.dateEndModel == null || this.dateEndModel == '' || this.time2 == null || this.time2 == ''){

        Swal.fire({
                text: 'Please Fill End Date and Time !',
                icon: 'warning',
            })
        return false

      }

      if(this.employees.length == 0){
        Swal.fire({
                text: 'Please Add Employee to List !',
                icon: 'warning',
            })
        return false
      }

      if(this.fileUpload){
          this.imageExist = null
      } else {
          this.imageExist = 'Y'
      }
      this.dateStartFormatted = (new Date(new Date(this.dateStartModel) - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)
      this.dateEndFormatted = (new Date(new Date(this.dateEndModel) - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10) 

      var start = this.dateStartFormatted + ' ' + this.time
      var end = this.dateEndFormatted + ' ' + this.time2

      if (new Date(end) < new Date(start)) {
          Swal.fire({
              text: 'Please Check Start and End Datetime !',
              icon: 'warning',
          })
          return false
      }
      
      this.dialogCreateRequest = false

      this.$store.dispatch('setOverlay', true)

      let formData = new FormData()
      formData.append('idemployee', this.$store.state.user.id)
      formData.append('company', this.$store.state.user.employee.company_id)
      formData.append('ijin', this.addIjinModel ? this.addIjinModel : '')
      formData.append('startDate', start)
      formData.append('endDate', end)
      formData.append('remark', this.addRemarkModel ? this.addRemarkModel : '')
      formData.append('image', this.imageExist)
      formData.append('tr_type', this.tr_type)
      formData.append('dept_id', this.$store.state.user.employee.dept_id ? this.$store.state.user.employee.dept_id.trim() : '')
      formData.append('aprv_level', this.aprv_level ? this.aprv_level : '')
      formData.append('employees',JSON.stringify(this.choice_employees))
      formData.append('file', this.fileUpload)

      await axios.post(`${process.env.VUE_APP_URL}/v2/hris/overtime/store`, formData, { 
          headers: { 
              'Content-Type': 'multipart/form-data',
              'Access-Control-Allow-Origin': '*',
          } 
      }).then(res => {
        this.clear()
        this.populateData()
        this.$store.dispatch('setOverlay', false)
        this.fileUpload = ''
      }).catch(err => {
        this.dialogCreateRequest = true
        this.$store.dispatch('setOverlay', false)
      })

    },
    async uploadFile(id){

      let formData = new FormData()
      formData.append('employee_id', this.$store.state.user.id)
      formData.append('doc_id', id)

      for( var i = 0; i < this.fileUpload.length; i++ ){
          let file = this.fileUpload[i];
          formData.append('file[' + i + ']', file)
      }

      await axios.post(`${process.env.VUE_APP_URL}/api/AbsensePermit/uploadFile`, formData, { 
          headers: { 
              'Content-Type': 'multipart/form-data',
              'Access-Control-Allow-Origin': '*',
          } 
      }).then(res => {
          this.fileUpload = []
      }).catch(err => {
          console.log(err)
      })


    },
    async showImage(item){
      this.$store.dispatch('setOverlay', true)

      this.imageList = []

      var reqBody = {
        'id': item.doc_id,
        'idemployee': item.idemployee,
        'company_id': item.company_id,
      }

      const respData = await user.getImages('', reqBody, false, false, false)

      if (respData.status === 200) {
        console.log(respData);
        this.dialogShowImage = true

        for (let index = 0; index < respData.data.length; index++) {
            const element = respData.data[index];
            this.imageList.push(process.env.VUE_APP_URL + '/img/hrd/emp/izin/' + element.file_name)
        }

      this.$store.dispatch('setOverlay', false)

      }
    },
    async deleteAbsence(id){
      Swal.fire({
          text: 'Do you want Delete this Overtime ?',
          showDenyButton: true,
          confirmButtonText: 'Yes',
          confirmButtonColor: 'green',
          denyButtonText: 'Cancel',
          denyButtonColor: 'red'
          }).then((result) => {
          if (result.isConfirmed) {
              
              axios.post(`${process.env.VUE_APP_URL}/api/AbsensePermit/deleteAbsence`, {

                  id: id,
                  // Tambaan wajib user yang create untuk delete
                  id_employee: this.$store.state.user.id,
                  tr_type: 'SPL',
                  company_id: this.$store.state.user.company_id
              },
              { 
                  headers: { 
                      'Accept': 'application/json',
                      'Content-Type': 'application/json',
                      'Access-Control-Allow-Origin': '*',
                      Authorization: `Bearer ${this.$store.getters.isTokenUser}`
                  } 
              })
              .then(res => {

                  this.populateData()
                  
              })
              .catch(err => {
                  
                  console.log(err);

              })

          }
      })
    },
    async getEmployee(){
      var reqBody = {
        entity_id: this.$store.state.user.employee.company_id.trim(),
        dep_id: this.$store.state.user.employee.dept_id ? this.$store.state.user.employee.dept_id.trim() : '',
        division_id: this.$store.state.user.employee.division_id ? this.$store.state.user.employee.division_id.trim() : '',
        section_id: this.$store.state.user.employee.section_id ? this.$store.state.user.employee.section_id.trim() : '',
        group_id: this.$store.state.user.employee.group_id ? this.$store.state.user.employee.group_id.trim() : ''
      }

      const respData = await employee.index('', reqBody, false, false, false)

      if (respData.status === 200) {
        this.employees = respData.data
      }
    },
    addItem(){
      this.choice_employees.push({
        idemployee: '',
        name: '',
        id: ''
      });
    },
    getEmployeeID(event, index){
      this.choice_employees[index].name = event.name
      this.choice_employees[index].id = event.idemployee
    },
    deleteItem(emp, index){
      this.choice_employees.splice(index, 1);
    }
  },
  async mounted() { 
    this.$store.dispatch('setOverlay', true)
    await this.maxApprove()
    await this.initialize()
    await this.getEmployee()
    // await this.populateAbsenceList('IMP')
    this.$store.dispatch('setOverlay', false)
  },
  watch: {
  }
}
</script>
  
<style scoped>
.tr_datatable{
  background-color: #F5F7F8 !important;
}
  
</style>