import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import store from "../store/index.js";
import DashboardLayout from "../components/Layouts/Default_Dashboard.vue";
import DefaultLayout from "../components/Layouts/DefaultLayout.vue";
import Overview from "../views/Overview.vue";
import Profile from "../views/Profile.vue";

import Content from "../components/Layouts/Content.vue";

import PageNotFound from "../views/PageNotFound.vue"


Vue.use(VueRouter);

function checkAuth(to, from, resolve, reject) {
  console.log(store.getters.isUserLoggedIn, store.state.user.isLoggedIn);
  if (store.getters.isUserLoggedIn && store.state.user.isLoggedIn) {
    resolve();
  } else {
    router.push({ path: "/" });
  }
}

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/about",
    name: "About",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/About.vue"),
  },
  {
    path: "/password/reset",
    name: "ForgetPassword",
    component: () =>
      import("../views/ForgetPassword.vue"),
  },
  {
    path: "/reset/password/:link",
    name: "ResetPassword",
    component: () =>
      import("../views/ResetPassword.vue"),
  },
  {
    path: "/approve/:idemployee/:token",
    name: "LoginApproval",
    component: () =>
      import("../views/LoginApproval.vue"),
  },
  {
    path: "/employee",
    component: DashboardLayout,
    redirect: "/employee/dashboard",
    beforeEnter: checkAuth,
    children: [
      {
        path: 'dashboard',
        name: 'Overview',
        component: Overview
      },
      {
        path: 'profile',
        name: 'Profile',
        component: Profile
      },
      {
        path: 'approval',
        name: 'approval',
        component: () => import("../views/Approval.vue"),
      },
      {
        path: 'overtime',
        name: 'overtime',
        component: () => import("../views/Overtime.vue"),
      },
      {
        path: 'ptk',
        name: 'ptk',
        component: () => import("../views/PTK.vue"),
      },
    ]
  },

  { path: "*", 
    component: DefaultLayout,
    redirect: "/PageNotFound",
    children: [
      {
        path: 'PageNotFound',
        name: 'PageNotFound',
        component: PageNotFound
      }
    ]
  }

];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

let programmatic = false
;(['push', 'replace', 'go', 'back', 'forward']).forEach(methodName => {
  const method = router[methodName]
  router[methodName] = (...args) => {
    programmatic = true
    method.apply(router, args)
  }
})

router.beforeEach((to, from, next) => {
  // name is null for initial load or page reload
  if (from.name === null || programmatic) {
    // triggered bu router.push/go/... call
    // route as usual
    next()
  } else {
    // triggered by user back/forward 
    // do not route
    next(false)
  }
  programmatic = false // clear flag
})

export default router;
