<template>
    <div>
        <v-system-bar
            color="#e8ecef"
            height="64"
            class="pl-5 pr-5"
        >
            <v-toolbar-title color="#000" class="ml-4" style="width: 400px;">
                <span class="bold hidden-sm-and-down">Human Resource Information System</span>
                <span class="bold hidden-sm-and-up">HRIS</span>
            </v-toolbar-title>
            <v-spacer />
            <v-menu
                bottom
                left
            >
                <template v-slot:activator="{ on, attrs }">
                    <v-badge
                    color="green"
                    :content="notifications.length > 0 ? notifications.length : '0'"
                    overlap
                    class="mr-3"
                    >
                        <v-btn v-on="on" class="border-12" color="#bdbaba" outlined >
                            <v-icon color="#000">
                                mdi-bell
                            </v-icon>
                        </v-btn>
                    </v-badge>
                </template>
                <v-list dense two-line class="mt-0 pt-0 mb-0 pb-0" max-width="400">
                    <v-list-item-group
                    >
                        <v-list-item v-for="(data, key) in notifications" :key="key" @click="markRead(data)">
                            <v-list-item-icon>
                                <v-icon>mdi-message</v-icon>
                            </v-list-item-icon>
                            <v-list-item-content class="pt-0 pb-0">
                                <v-list-item-title class="mb-0 pb-0">{{data.notif_title}}</v-list-item-title>
                                <v-list-item-subtitle class="font-12 mb-0 pb-0">{{data.notif_message}}</v-list-item-subtitle>
                            </v-list-item-content>
                            <v-list-item-action>
                                <v-list-item-action-text>{{ (new Date(new Date(data.created_at) - (new Date()).getTimezoneOffset() *
                                            60000)).toISOString().substr(0, 10) }}</v-list-item-action-text>
                            </v-list-item-action>
                        </v-list-item>
                    </v-list-item-group>
                </v-list>
            </v-menu>
            
            <v-btn class="border-12 mr-3 hidden-sm-and-down" color="#bdbaba" outlined >
                <v-icon color="#000">
                    mdi-cog
                </v-icon>
            </v-btn>
            <v-menu offset-y>
                <template v-slot:activator="{ on }">
                    <v-toolbar-title class="" v-on="on" style="font-size:0.99rem; cursor:pointer; border: 1px solid #bdbaba; border-radius: 12px;">
                        <v-avatar size="35px" item class="mr-1">
                            <v-img :src="avatar" alt="Admin"/>
                        </v-avatar>
                        {{$store.state.user.name}}
                        <v-icon class="ml-2">
                            mdi-chevron-down
                        </v-icon>
                    </v-toolbar-title>
                </template>
                <v-list dense>
                    <v-list-item-group
                        color="secondary"
                    >
                        <v-list-item>
                            <v-card class="rounded-l border-12" width="100%">
                                <v-card-text class="p-1">
                                    <div class="d-flex">
                                        <v-avatar size="45px" item class="mr-1">
                                            <v-img :src="avatar" alt="Admin"/>
                                        </v-avatar>
                                        <p class="mt-3">
                                            {{$store.state.user.name}}
                                        </p>
                                    </div>
                                </v-card-text>
                            </v-card>
                        </v-list-item>
                        <v-list-item class="mt-2" to="/employee">
                            <v-list-item-icon>
                                <v-icon>mdi-lock</v-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                                <v-list-item-title>Profile</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                        <v-list-item>
                            <v-btn type="submit" block class="border-12 mr-12 pl-12 pr-12 pt-2 pb-2 mt-3" @click="logout()">Logout</v-btn>
                        </v-list-item>
                    </v-list-item-group>
                </v-list>
            </v-menu>
        </v-system-bar>
        <v-app-bar  color="primary" dark height="50" outlined style="box-shadow: 0px 0px 0px -2px rgba(0, 0, 0, 0.2), 0px 1px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 4px 0px rgba(0, 0, 0, 0.12) !important;">
            <v-tabs class="ml-4" v-model="keyTab" :value="keyTab" show-arrows color="#fff">
                <v-tab
                    v-for="(tab) in tabs"
                    :key="tab.route"  
                    :to="tab.url"
                >
                    <v-icon class="mr-2" color="#fff">{{ tab.icon }}</v-icon> {{ tab.name }}
                </v-tab>
            </v-tabs>
            <v-snackbar v-model="snackbar.visible" :color="snackbar.color" :multi-line="snackbar.mode === 'multi-line'" :timeout="snackbar.timeout" :top="snackbar.position === 'top'">
                <v-layout align-center pr-4>
                <v-icon class="pr-3" dark large>{{ snackbar.icon }}</v-icon>
                <v-layout column>
                    <div>
                    <strong>{{ snackbar.title }}</strong>
                    </div>
                    <div>{{ snackbar.text }}</div>
                </v-layout>
                </v-layout>
                <v-btn v-if="snackbar.timeout === 0" icon @click="snackbar.visible = false">
                <v-icon>clear</v-icon>
                </v-btn>
            </v-snackbar>
        </v-app-bar>
    </div>
</template>

<script>
import {user} from "@/backend-api/user"
import {notification} from "@/backend-api/notification"

import { mapState } from 'vuex'

export default {
    data() {
        return {
            loading: false,
            snackbar: {
                color: null,
                icon: null,
                mode: null,
                position: "top",
                text: null,
                timeout: 7500,
                title: null,
                visible: false
            },
            timeout: 7500,
            keyTab: null,
            active_tab : 'dashboard',
            tabs: [
                // {
                //     name: 'Home',
                //     route: 'dashboard',
                //     url: '/employee/dashboard',
                //     icon: 'mdi-home'
                // },
                // {
                //     name: 'Approval Absence',
                //     route: 'approval',
                //     url: '/employee/approval',
                //     icon: 'mdi-file-check'
                // }
            ],
            notifications: []
        }
    },
    methods: {
        drawer(){
            return this.$store.commit('SET_DRAWER_ADMIN')
        },
        checkColor(count){
            return count ? 'red' : 'green'
        },
        logout(){
            this.loading = true

            this.$store.dispatch("logoutUser").then(() => {
                this.snackbar = {
                    color: "success",
                    icon: "mdi-checkbox-marked-circle",
                    mode: "multi-line",
                    position: "top",
                    timeout: 7500,
                    title: "Success",
                    text: "Successfully Logout Sistem",
                    visible: true
                };
                this.loading = false
                this.$router.push('/')
            });
        },
        async getPullData(){
            this.tabs = []

            var reqBody = {
                company: this.$store.state.user.employee.company_id.trim(),
                idemployee: this.$store.state.user.id,
                tr_type: 'IMP'
                // dep_id: this.$store.state.user.employee.dept_id ? this.$store.state.user.employee.dept_id.trim() : ''
            }

            var reqBodySPL = {
                company: this.$store.state.user.employee.company_id.trim(),
                idemployee: this.$store.state.user.id,
                tr_type: 'SPL'
                // dep_id: this.$store.state.user.employee.dept_id ? this.$store.state.user.employee.dept_id.trim() : ''
            }

            const respData = await user.cekUserApprove('', reqBody, false, false, false)
            const respDataSPL = await user.cekUserApprove('', reqBodySPL, false, false, false)

            const respDataNotif = await notification.index('', reqBodySPL, false, false, false)


            if (respData.status === 200 && respDataSPL.status === 200 && respDataNotif.status === 200) {
                this.tabs.push(
                    {
                        name: 'Home',
                        route: 'dashboard',
                        url: '/employee/dashboard',
                        icon: 'mdi-home'
                    },
                )

                if (respData.data.length > 0) {
                    this.tabs.push(
                        {
                            name: 'Approval Absence',
                            route: 'approval',
                            url: '/employee/approval',
                            icon: 'mdi-file-check'
                        }
                    )
                }
                
                if (respDataSPL.data.length > 0) {
                    this.tabs.push(
                        {
                            name: 'Approval Overtime',
                            route: 'overtime',
                            url: '/employee/overtime',
                            icon: 'mdi-clock-time-seven'
                        }
                    )
                }

                if (respDataSPL.data.length > 0) {
                    this.tabs.push(
                        {
                            name: 'Approval PTK',
                            route: 'ptk',
                            url: '/employee/ptk',
                            icon: 'mdi-account-convert'
                        }
                    )
                }

                this.notifications = respDataNotif.data
            }
        },
        async markRead(data){
            var reqBody = {
                id: data.id,
                idemployee: data.idemployee
                // dep_id: this.$store.state.user.employee.dept_id ? this.$store.state.user.employee.dept_id.trim() : ''
            }
            const respDataNotif = await notification.markReadNotif('', reqBody, false, false, false)

            if (respDataNotif.status === 200){
                await this.getPullData()
                this.$router.push(data.notif_url);
            }
        }
    },
    computed: mapState([
        'avatar'
    ]),
    async mounted(){
        this.$store.dispatch('getHeaderAvatar')
        await this.getPullData()
    }
}
</script>