<template>
  <div>
    <v-system-bar
        color="grey"
        lights-out
        height="40"
        class="pl-5 pr-5 mb-0 pb-0"
    >
        <p class="hidden-sm-and-down mt-4">Tentang HRIS</p> 
        <v-spacer></v-spacer>
        <!-- <span class="mr-5 hidden-sm-and-down">{{ this.profile ? this.profile.group : ''}}</span> -->
        <span class="mr-5">{{this.time }}</span>
        <span>{{ this.date_now }}</span>
    </v-system-bar>
    <v-app id="inspire" class="outer mt-0 pt-0" style="background-position: center;
      background-repeat: no-repeat;
      background-size: cover;">
      <div class="overlay-green"></div>
      <v-main>
        <v-container class="fill-height mt-0 pt-0 pl-lg-5 pr-lg-5" style="max-width: 80em;">
          <v-row class="pt-0 mt-0">
            <v-col cols="12" xs="12" sm="8" md="8">
              <v-card class="rounded-xl p-0" min-height="557" outlined style="background: inherit !important;border: 1px solid #fff;">
                <v-carousel
                  :continuous="true"
                  :cycle="cycle"
                  :show-arrows="true"
                  delimiter-icon="mdi-minus"
                  hide-delimiter-background
                >
                  <v-carousel-item>
                    <v-img class="rounded-xl text-center" src="@/assets/businessman-application-human-digital-business.jpg" max-height="340" alt="HRIS">
                    </v-img>
                    <v-card-text>
                      <h6 style="color: #fff;">Word of the Day</h6>
                      <div class="text--white" style="color: #fff;">
                        "Do your job with sincerity, then you will achieve success with little competition in your work." -Elbert Hubbard
                      </div>
                    </v-card-text>
                    <v-card-actions>
                      <v-btn
                        text
                        color="teal accent-4"
                      >
                        Learn More
                      </v-btn>
                    </v-card-actions>
                  </v-carousel-item>
                  <v-carousel-item>
                    <v-img class="rounded-xl" src="@/assets/hand-using-laptop-computer-with-virtual-screen-document-online-approve-paperless-quality-assurance-erp-management-concept.jpg" max-height="340" alt="HRIS">
                    </v-img>
                    <v-card-text>
                      <h6 style="color: #fff;">Word of the Day</h6>
                      <div class="text--white" style="color: #fff;">
                        "Without work, all life decays. However, when work is soulless, life suffocates and dies." - Albert Camus
                      </div>
                    </v-card-text>
                    <v-card-actions>
                      <v-btn
                        text
                        color="teal accent-4"
                      >
                        Learn More
                      </v-btn>
                    </v-card-actions>
                  </v-carousel-item>
                  <v-carousel-item>
                    <v-img class="rounded-xl" src="@/assets/hands-with-laptop-virtual-world-map.jpg" max-height="340" alt="HRIS">
                    </v-img>
                    <v-card-text>
                      <h6 style="color: #fff;">Word of the Day</h6>
                      <div class="text--white" style="color: #fff;">
                        "No one in this world is famous solely because of the talent they have, but by working they can turn talent into something genius." -Anna Pavlova
                      </div>
                    </v-card-text>
                    <v-card-actions>
                      <v-btn
                        text
                        color="teal accent-4"
                      >
                        Learn More
                      </v-btn>
                    </v-card-actions>
                  </v-carousel-item>
                </v-carousel>
              </v-card>
            </v-col>
            <v-col cols="12" xs="12" sm="4" md="4">
              <v-card class="elevation-12 rounded-xl p-3" min-height="557">
                  <v-progress-linear
                      :active="loading"
                      :indeterminate="loading"
                      absolute
                      bottom
                      color="blue accent-4"
                  ></v-progress-linear>
                  <v-card-title style="border-bottom: 1px solid rgba(0,0,0,.125);text-align: center;">
                    <v-img
                      class="mx-auto text-center"
                      lazy-src="../assets/hr.png"
                      src="../assets/hr.png"
                      max-width="200"
                    ></v-img>
                  </v-card-title>
                  <h5 class="pl-5 pt-5">Login to HRIS</h5>
                  <v-card-text>
                      <form>
                        <v-text-field
                        solo
                        v-model="username"
                        :error-messages="errors"
                        label="ID Employee"
                        append-icon="mdi-user"
                        hint="Please input your username"
                        counter
                        required
                        class="border-12"
                        ></v-text-field>
                        <div v-if="result === 'ok' || result === 'new'">
                          <v-text-field
                              solo
                              v-model="password"
                              :error-messages="errors"
                              label="Password"
                              :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                              :type="show ? 'text' : 'password'"
                              @click:append="show = !show"
                              @keyup.enter="result === 'ok' ? login() : submitNewPassword()"
                              hint="Please input your password"
                              counter
                              class="border-12"
                              hide-details=""
                              required>
                          </v-text-field>
                        </div>
                      </form>
                      <v-snackbar v-model="snackbar.visible" :color="snackbar.color" :multi-line="snackbar.mode === 'multi-line'" :timeout="snackbar.timeout" :top="snackbar.position === 'top'">
                        <v-layout align-center pr-4>
                          <v-icon class="pr-3" dark large>{{ snackbar.icon }}</v-icon>
                          <v-layout column>
                            <div>
                              <strong>{{ snackbar.title }}</strong>
                            </div>
                            <div>{{ snackbar.text }}</div>
                          </v-layout>
                        </v-layout>
                        <v-btn v-if="snackbar.timeout === 0" icon @click="snackbar.visible = false">
                          <v-icon>clear</v-icon>
                        </v-btn>
                      </v-snackbar>
                  </v-card-text>
                  <v-card-actions>
                      <!-- <v-spacer /> -->
                      <div class="text-right">
                        <a href="https://svr03.kencana.org/" style="color:#0078d4"><b>Cancel</b></a>
                      </div>
                      <v-spacer />
                      <v-btn v-if="result === 'ok'" class="rounded-l pl-12 pr-12" color="primary" @click="login()">Login</v-btn>
                      <v-btn v-else-if="result === 'new'" class="rounded-l pl-12 pr-12" color="primary" @click="submitNewPassword()">Confirm New Password</v-btn>
                      <v-btn v-else class="rounded-l pl-12 pr-12" color="primary" @click="checkUser()">Check Employee</v-btn>
                      
                  </v-card-actions>
                  <div class="d-flex">
                    <v-spacer />
                    <router-link to="/password/reset">
                      Forget Password ?
                    </router-link>
                  </div>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
      </v-main>
    </v-app>
  </div>
  
</template>

<script>
import {user} from "@/backend-api/user"

export default {
    name: 'AdminLogin',
    data(){
        return {
          timeout: 7500,
          cycle: true,
          slides: [
            'https://my.isellercommerce.com/assets/images/news/blog/2022-05-16.png',
            'https://my.isellercommerce.com/assets/images/news/blog/2022-04-29-3.png',
            'https://my.isellercommerce.com/assets/images/news/blog/2022-04-29-1.png',
            'https://my.isellercommerce.com/assets/images/news/blog/2022-04-29-2.png',
            'https://my.isellercommerce.com/assets/images/news/blog/2022-05-16.png',
          ],
          username: '',
          password: '',
          error: true,
          show: false,
          errors: [],
          loading: false,
          snackbar: {
            color: null,
            icon: null,
            mode: null,
            position: "top",
            text: null,
            timeout: 7500,
            title: null,
            visible: false
          },
          timeout: 7500,
          result: '',
          interval: null,
          time: null, 
          date_now: null,
          intervalData: null,
        }
    },
    mounted(){
      this.$store.dispatch('setOverlay', false)
    },
    methods: {
      clear(){
        this.username = ''
        this.password = ''
      },
      async checkUser(){          
        this.$store.dispatch('setOverlay', true)

        var reqBody = {
          'id': this.username
        }

        const respData = await user.checkUser('', reqBody, false, false, false)

        if (respData.status === 200) {
          this.result = respData.data.result

          if (respData.data.result == 'ok') {
            
          } else if(respData.data.result == 'new'){

          } else {
            this.snackbar = {
                color: "info",
                icon: "mdi-alert-circle",
                mode: "multi-line",
                position: "top",
                timeout: 7500,
                title: "Info",
                text: respData.data.result,
                visible: true
              };
          }

          this.$store.dispatch('setOverlay', false)
        }
      },
      async login(){
        this.loading = true
        this.$store.dispatch('setOverlay', true)

        let username = this.username;
        let password = this.password;

        this.$store
        .dispatch("loginUser", { username, password })
        .then((res) => {
          console.log(res);
          if (res.data.result === true || res.data.result === 1) {
            this.clear()
            this.loading = false
            this.$store.dispatch('setOverlay', false)

            this.$router.push('/employee/dashboard');
            
            this.snackbar = {
              color: "success",
              icon: "mdi-checkbox-marked-circle",
              mode: "multi-line",
              position: "top",
              timeout: 7500,
              title: "Success",
              text: "Successfully Login",
              visible: true
            };
          } else {
            this.loading = false
            this.$store.dispatch('setOverlay', false)
            this.snackbar = {
              color: "info",
              icon: "mdi-alert-circle",
              mode: "multi-line",
              position: "top",
              timeout: 7500,
              title: "Info",
              text: 'Sorry, your password is wrong',
              visible: true
            };
          }
          
        }).catch((err) => {
          console.log(err);
          this.$store.dispatch('setOverlay', false)
          this.loading = false
          this.snackbar = {
            color: "error",
            icon: "mdi-alert-circle",
            mode: "multi-line",
            position: "top",
            timeout: 7500,
            title: "Error",
            text: err.response,
            visible: true
          };
        });
      }, 
      async submitNewPassword(){
        this.$store.dispatch('setOverlay', true)

        var reqBody = {
          'username': this.username,
          'password': this.password
        }

        const respData = await user.setNewPassword('', reqBody, false, false, false)

        if (respData.status === 200) {
          await this.login()
          this.$store.dispatch('setOverlay', false)
        }
      }
    },
    beforeDestroy() {
    // prevent memory leak
        clearInterval(this.interval)
    },
    created() {
        // update the time every second
        this.interval = setInterval(() => {
            this.time = Intl.DateTimeFormat('sv-SE', {timeZone: 'Asia/Jakarta', hour: '2-digit', minute: '2-digit', second: '2-digit', hour12: false}).format()
            this.date_now = Intl.DateTimeFormat('id', {timeZone: 'Asia/Jakarta', day: 'numeric', month: 'long', weekday: "long", year: "numeric"}).format()
        }, 1000)
    }
}
</script>