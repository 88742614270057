import {backendApi} from "./backend-api"

export const employee = (() =>{
    const test = (() => {
        return ""
    })

    const index = ( async (subPath, reqBody) => {
        var url = '/v2/hris/employee'
        if(subPath != null || subPath != ""){
            url = url + subPath;
        }
        return await backendApi.fetchCore(url, reqBody, false, false, false)
    })


    return {test, index};

})()