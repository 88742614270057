import {backendApi} from "./backend-api"

export const notification = (() =>{
    const test = (() => {
        return ""
    })

    const index = ( async (subPath, reqBody) => {
        var url = '/v2/hris/getDataNotif'
        if(subPath != null || subPath != ""){
            url = url + subPath;
        }
        return await backendApi.fetchCore(url, reqBody, false, false, false)
    })

    const markReadNotif = ( async (subPath, reqBody) => {
        var url = '/v2/hris/markReadNotif'
        if(subPath != null || subPath != ""){
            url = url + subPath;
        }
        return await backendApi.fetchCore(url, reqBody, false, false, false)
    })


    return {test, index, markReadNotif};

})()