import {backendApi} from "./backend-api"

export const user = (() =>{
    const test = (() => {
        return ""
    })

    const index = ( async (subPath, reqBody) => {
        var url = '/api/AbsensePermit/getData'
        if(subPath != null || subPath != ""){
            url = url + subPath;
        }
        return await backendApi.fetchCore(url, reqBody, false, false, false)
    })

    const getDataApprove = ( async (subPath, reqBody) => {
        var url = '/api/AbsensePermit/getDataApprove'
        if(subPath != null || subPath != ""){
            url = url + subPath;
        }
        return await backendApi.fetchCore(url, reqBody, false, false, false)
    })

    const getDataApprovePTK = ( async (subPath, reqBody) => {
        var url = '/api/AbsensePermit/getDataApprovePTK'
        if(subPath != null || subPath != ""){
            url = url + subPath;
        }
        return await backendApi.fetchCore(url, reqBody, false, false, false)
    })

    const getDataDetailsPTK = ( async (subPath, reqBody) => {
        var url = '/api/AbsensePermit/getDataDetailsPTK'
        if(subPath != null || subPath != ""){
            url = url + subPath;
        }
        return await backendApi.fetchCore(url, reqBody, false, false, false)
    })

    const fetchCompany = ( async (subPath) => {
        var url = '/api/AbsensePermit'
        if(subPath != null || subPath != ""){
            url = url + subPath;
        }
        return await backendApi.fetchCore(url, null, false, false, false)
    })

    const checkUser = ( async (subPath, reqBody) => {
        var url = '/api/AbsensePermit/checkUser'
        if(subPath != null || subPath != ""){
            url = url + subPath;
        }
        return await backendApi.fetchCore(url, reqBody, false, false, false)
    })

    const loginUser = ( async (subPath, reqBody) => {
        var url = '/api/AbsensePermit/submitLogin'
        if(subPath != null || subPath != ""){
            url = url + subPath;
        }
        return await backendApi.fetchCore(url, reqBody, false, false, false)
    })

    const setNewPassword = ( async (subPath, reqBody) => {
        var url = '/api/AbsensePermit/setNewPassword'
        if(subPath != null || subPath != ""){
            url = url + subPath;
        }
        return await backendApi.fetchCore(url, reqBody, false, false, false)
    })

    const getImages = ( async (subPath, reqBody) => {
        var url = '/api/AbsensePermit/getImages'
        if(subPath != null || subPath != ""){
            url = url + subPath;
        }
        return await backendApi.fetchCore(url, reqBody, false, false, false)
    })

    const submitData = ( async (subPath, reqBody) => {
        var url = '/api/AbsensePermit/submitData'
        if(subPath != null || subPath != ""){
            url = url + subPath;
        }
        return await backendApi.fetchCore(url, reqBody, false, false, false)
    })

    const maxApprove = ( async (subPath, reqBody) => {
        var url = '/api/AbsensePermit/maxApprove'
        if(subPath != null || subPath != ""){
            url = url + subPath;
        }
        return await backendApi.fetchCore(url, reqBody, false, false, false)
    })

    const cekUserApprove = ( async (subPath, reqBody) => {
        var url = '/api/AbsensePermit/cekUserApprove'
        if(subPath != null || subPath != ""){
            url = url + subPath;
        }
        return await backendApi.fetchCore(url, reqBody, false, false, false)
    })

    const cekUserApproveNum = ( async (subPath, reqBody) => {
        var url = '/v2/hris/cekUserApproveNum'
        if(subPath != null || subPath != ""){
            url = url + subPath;
        }
        return await backendApi.fetchCore(url, reqBody, false, false, false)
    })

    const cekUserApproveNums = ( async (subPath, reqBody) => {
        var url = '/v2/hris/cekUserApproveNums'
        if(subPath != null || subPath != ""){
            url = url + subPath;
        }
        return await backendApi.fetchCore(url, reqBody, false, false, false)
    })

    const cekUserApproveNumsPTK = ( async (subPath, reqBody) => {
        var url = '/v2/hris/cekUserApproveNumsPTK'
        if(subPath != null || subPath != ""){
            url = url + subPath;
        }
        return await backendApi.fetchCore(url, reqBody, false, false, false)
    })

    const approve = ( async (subPath, reqBody) => {
        var url = '/v2/hris/approve'
        if(subPath != null || subPath != ""){
            url = url + subPath;
        }
        return await backendApi.fetchCore(url, reqBody, false, false, false)
    })

    const reject = ( async (subPath, reqBody) => {
        var url = '/v2/hris/reject'
        if(subPath != null || subPath != ""){
            url = url + subPath;
        }
        return await backendApi.fetchCore(url, reqBody, false, false, false)
    })

    const getAvatar = ( async (subPath, reqBody) => {
        var url = '/api/AbsensePermit/getAvatar'
        if(subPath != null || subPath != ""){
            url = url + subPath;
        }
        return await backendApi.fetchCore(url, reqBody, false, false, false)
    })

    const updateAvatar = ( async (subPath, reqBody) => {
        var url = '/api/AbsensePermit/updateAvatar'
        if(subPath != null || subPath != ""){
            url = url + subPath;
        }
        return await backendApi.fetchCore(url, reqBody, false, false, false)
    })

    const cekIDEmployee = ( async (subPath, reqBody) => {
        var url = '/v2/hris/cekIDEmployee'
        if(subPath != null || subPath != ""){
            url = url + subPath;
        }
        return await backendApi.fetchCore(url, reqBody, false, false, false)
    })

    const cekLinkResetPassword = ( async (subPath, reqBody) => {
        var url = '/v2/hris/cekLinkResetPassword'
        if(subPath != null || subPath != ""){
            url = url + subPath;
        }
        return await backendApi.fetchCore(url, reqBody, false, false, false)
    })

    const resetPassword = ( async (subPath, reqBody) => {
        var url = '/v2/hris/resetPassword'
        if(subPath != null || subPath != ""){
            url = url + subPath;
        }
        return await backendApi.fetchCore(url, reqBody, false, false, false)
    })

    const cekTokenApproval = ( async (subPath, reqBody) => {
        var url = '/v2/hris/cekTokenApproval'
        if(subPath != null || subPath != ""){
            url = url + subPath;
        }
        return await backendApi.fetchCore(url, reqBody, false, false, false)
    })

    const chengePassword = (async (url,reqBody)=>{
        var url = '/v2/hris/changePassword'
        return await backendApi.fetchCore(url, reqBody,false,false,false)
    })

    const dashboard = (async (url,reqBody)=>{
        var url = '/api/AbsensePermit/dashboard'
        return await backendApi.fetchCore(url, reqBody,false,false,false)
    })

    return {checkUser,loginUser, test, setNewPassword, index, getImages, fetchCompany, submitData, maxApprove, cekUserApprove, cekUserApproveNum, approve, reject, cekUserApproveNums, getDataApprove, getAvatar, updateAvatar, cekIDEmployee, cekLinkResetPassword, resetPassword, cekTokenApproval,chengePassword, dashboard, getDataApprovePTK, getDataDetailsPTK, cekUserApproveNumsPTK};

})()