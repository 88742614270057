<template>
  <div>
    <v-container fluid class="mt-3">
      <v-row>
        <v-col cols="12" class="mb-0 mt-0">
          <div class="text-justified align-items-center">
            <h6 class="text-dark font-weight-semibold mb-3 mt-2">
              Hi, Welcome Back <span class="text-primary text-capitalize">{{$store.state.user.name}} !</span>
            </h6>
          </div>
        </v-col>
        <v-col cols="12" sm="12" md="3">
          <v-card class="rounded-l purple lighten-4" outlined height="198px">
            <v-card-title class="">
              <div class="d-flex">
                <v-icon color="purple">
                  mdi-bucket
                </v-icon>
                <div class="ml-3">
                  Annual Leave
                </div>
              </div>
            </v-card-title>
            <v-card-text  class="p-3">
              <v-container class="py-auto fill-height">
                <v-row align="center" justify="center">
                  <v-col cols="12" sm="12" md="8">
                    <h2 style="font-weight:600" class="pa-0 ma-0 purple--text text-center">
                      {{ annual_leave ? annual_leave : 0 }}
                    </h2>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
          </v-card>
        </v-col> 
        <v-col cols="12" sm="12" md="3">
          <v-card class="rounded-l green lighten-4" outlined height="198px">
            <v-card-title class="">
              <div class="d-flex">
                <v-icon color="green" >
                  mdi-chart-box-plus-outline
                </v-icon>
                <div class="ml-3">
                  Leave Usage
                </div>
              </div>
            </v-card-title>
            <v-card-text class="p-3">
              <v-container class="py-auto fill-height">
                <v-row align="center" justify="center">
                  <v-col cols="12" sm="12" md="8">
                    <h2 style="font-weight:600" class="pa-0 ma-0 green--text text-center">
                      {{leave_usage ? leave_usage : 0}}
                    </h2>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12" sm="12" md="3">
          <v-card class="rounded-l lighten-4" color="#fff" outlined height="198px">
            <v-card-title class="">
              <div class="d-flex">
                <v-icon color="black">
                  mdi-chart-bubble
                </v-icon>
                <div class="ml-3">
                  Remaining Days Off
                </div>
              </div>
            </v-card-title>
            <v-card-text class="p-3">
              <v-container class="py-auto fill-height">
                <v-row align="center" justify="center">
                  <v-col cols="12" sm="12" md="8">
                    <h2 style="font-weight:600" class="pa-0 ma-0 black--text text-center">
                      {{ remaining_days_off ? remaining_days_off : 0 }}
                    </h2>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12" sm="12" md="3">
          <v-card class="rounded-l" color="#e8ecef" outlined>
            <v-card-title class="mb-0 pb-0">
              <v-icon color="primary" class="mr-3">
                mdi-account-circle
              </v-icon>
              Info Employee
              <v-spacer></v-spacer>
              <v-icon color="primary" @click="dialogInfo = true">
                mdi-arrow-right
              </v-icon>
            </v-card-title>
            <v-card-text>
              <v-divider class="mb-0 pb-0"></v-divider>
              <v-list dense two-line class="ma-0 pl-0 pr-0 pt-0 pb-0 mt-3" color="#e8ecef">
                <v-list-item class="pl-0 pr-0" style="min-height: 40px !important;">
                  <v-list-item-content class="pt-0 pb-0">
                    <v-list-item-title style="font-size: 14px;">{{ $store.state.user.employee.company_name }}</v-list-item-title>
                    <v-list-item-subtitle class="font-12">Company</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item class="pl-0 pr-0" style="min-height: 40px !important;">
                  <v-list-item-content class="pt-0 pb-0">
                    <v-list-item-title style="font-size: 14px;">{{ $store.state.user.employee.office_name }}</v-list-item-title>
                    <v-list-item-subtitle class="font-12">Office</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item class="pl-0 pr-0" style="min-height: 40px !important;">
                  <v-list-item-content class="pt-0 pb-0">
                    <v-list-item-title style="font-size: 14px;">{{ $store.state.user.employee.dept_name ?  $store.state.user.employee.dept_name : '-' }}</v-list-item-title>
                    <v-list-item-subtitle class="font-12">Department</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <!-- <v-list-item class="pl-0 pr-0" style="min-height: 40px !important;">
                  <v-list-item-content class="pt-0 pb-0">
                    <v-list-item-title style="font-size: 14px;">{{ $store.state.user.employee.division_name ? $store.state.user.employee.division_name : '-' }}</v-list-item-title>
                    <v-list-item-subtitle class="font-12">Divisi</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item class="pl-0 pr-0" style="min-height: 40px !important;">
                  <v-list-item-content class="pt-0 pb-0">
                    <v-list-item-title style="font-size: 14px;">{{ $store.state.user.employee.sec_name ? $store.state.user.employee.sec_name : '-' }}</v-list-item-title>
                    <v-list-item-subtitle class="font-12">Section</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item class="pl-0 pr-0" style="min-height: 40px !important;">
                  <v-list-item-content class="pt-0 pb-0">
                    <v-list-item-title style="font-size: 14px;">{{ $store.state.user.employee.group_name ? $store.state.user.employee.group_name : '-' }}</v-list-item-title>
                    <v-list-item-subtitle class="font-12">Group Section</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item> -->
              </v-list>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <v-tabs
      v-model="currentTabIndex"
      color="#e8ecef"
      class="round_top col-sm-no-padding ml-3"
      icons-and-text
      show-arrows
    >
        <v-tabs-slider color="primary"></v-tabs-slider>

        <v-tab
            v-for="(tab, index) in tabs"
            :key="index"
            :href="'#tab-'+tab.aprv_num"
            active-class="active-tab"
            @click="populateAbsenceList(tab.tr_type)"
        >
          {{ tab.descr }}
        </v-tab>

    </v-tabs>
    <v-tabs-items v-model="currentTabIndex">
      <v-tab-item
        v-for="(tab, index) in tabs"
        :key="index"
        :value="'tab-'+tab.aprv_num"
      >
        <div v-if="tab.tr_type == 'IMP'"> 
          <v-card class="ma-0 rounded-xxl pb-4 elevation-5" height="auto" color="#fff">
            <v-card-text>
              <v-data-table
                dense
                :headers="headers"
                :items="itemLists"
                class="elevation-1 mb-5"
                :items-per-page="30"
                :footer-props="{
                    'items-per-page-options': [10, 20, 30, 40, 50, -1]
                }"
                :loading="$store.state.overlay"
                height="440"
                fixed-header
                :divider="true"
                :light="true"
                :search="searchItem" 
                :item-class="tr_datatable"
            >    
                <template v-slot:top>
                    <v-toolbar flat color="white" class="mt-2 mb-2">
                        <div class="d-flex w-100">
                            <v-text-field solo style="max-width: 300px;" class="border-12 mr-3 " v-model="searchItem" append-icon="mdi-magnify" label="Search" dense single-line hide-details></v-text-field>
                            <v-spacer></v-spacer>
                            <v-btn
                            small
                            color="#fff"
                            class="py-5 mr-3 hidden-sm-and-down"
                            @click="populateAbsenceList()"
                            >
                                <v-icon>mdi-cached</v-icon>
                            </v-btn>
                            <v-btn
                            small
                            color="#fff"
                            class="py-5 mr-3"
                            @click="dialogfilter = true"
                            >
                                <v-icon>mdi-filter-variant</v-icon>
                            </v-btn>
                            <!-- <v-btn
                            small
                            color="#005c37"
                            class="py-5 mr-3 text-white hidden-sm-and-down"
                            >
                                <v-icon>mdi-file-excel</v-icon>
                                <download-excel
                                    class="text"
                                    :fetch           = "exportExcel"
                                    :fields="headersColumn"
                                    :before-generate = "startDownload"
                                    :before-finish   = "finishDownload">
                                    Export Excel
                                </download-excel>
                            </v-btn> -->
                            <v-btn
                            small
                            color="success"
                            class="py-5 mr-0"
                            @click="showDialogAbsenceRequest()"
                            >
                            <v-icon>mdi-plus</v-icon>
                            </v-btn>
                        </div>
                    </v-toolbar>
                </template>
                <template v-slot:[`item.file_name`]="{ item }">
                    <v-btn text class="p-1" v-if="item.file_name == 'Y'" @click="showImage(item)" color="primary" small dark>view</v-btn>
                </template>
                <template v-slot:[`item.aprv`]="{ item }">
                  <div v-if="item.dt_reject != '01-01-1900'">
                    <v-btn text class="ma-1"  color="red" dense small dark>Rejected</v-btn>
                  </div>
                  <div v-else>
                    <!-- <v-btn text class="ma-1" v-if="max_aprv == 4 && (item.dt_aprv_4 != '01-01-1900' || item.dt_aprv_4 != null || item.dt_aprv_4 != '')" color="success" dense small dark>Approved</v-btn>
                    <v-btn text class="ma-1" v-if="max_aprv == 4 && (item.dt_aprv_4 == '01-01-1900' || item.dt_aprv_4 == null || item.dt_aprv_4 == '')" color="warning" dense small dark>Not Approved</v-btn>

                    <v-btn text class="ma-1" v-if="max_aprv == 3 && item.dt_aprv_3 != '01-01-1900'" color="success" dense small dark>Approved</v-btn>
                    <v-btn text class="ma-1" v-if="max_aprv == 3 && item.dt_aprv_3 == '01-01-1900'" color="warning" dense small dark>Not Approved</v-btn>

                    <v-btn text class="ma-1" v-if="max_aprv == 2 && item.dt_aprv_2 != '01-01-1900'" color="success" dense small dark>Approved</v-btn>
                    <v-btn text class="ma-1" v-if="max_aprv == 2 && item.dt_aprv_2 == '01-01-1900'" color="warning" dense small dark>Not Approved</v-btn>

                    <v-btn text class="ma-1" v-if="max_aprv == 1 && item.dt_aprv != '01-01-1900'" color="success" dense small dark>Approved</v-btn>
                    <v-btn text class="ma-1" v-if="max_aprv == 1 && item.dt_aprv == '01-01-1900'" color="warning" dense small dark>Not Approved</v-btn> -->
                    <v-btn text class="ma-1" v-if="item.stat == 'C'" color="success" dense small dark>Approved</v-btn>
                    <v-btn text class="ma-1"  v-if="item.stat == 'O'"  color="warning" dense small dark>Not Approved</v-btn>

                    <v-icon text class="ma-1" v-if="item.dt_aprv == '01-01-1900' && item.stat == 'O'" color="error" @click="deleteAbsence(item.doc_id)">mdi-delete</v-icon>

                    <!-- <v-chip class="ma-2" v-if="item.dt_aprv == '01-01-1900'" color="red" @click="deleteAbsence(item.doc_id)" dense small dark><v-icon>mdi-delete</v-icon></v-chip> -->

                  </div>
                </template>
                <template v-slot:[`item.stat`]="{ item }">
                    <v-btn text class="ma-1" v-if="item.stat == 'O'" color="blue" dense small dark>OPEN</v-btn>
                    <v-btn text class="ma-1" v-else-if="item.stat == 'C'" color="red" dense small dark>CLOSED</v-btn>
                    <v-icon class="ma-1" v-if="item.stat == 'O'" color="error" @click="deleteAbsence(item.doc_id)">mdi-delete</v-icon>
                    <!-- <v-chip class="ma-2" v-if="item.stat == 'O'" color="red" @click="deleteAbsence(item.doc_id)" dense small dark><v-icon>mdi-delete</v-icon></v-chip> -->
                </template>
              </v-data-table>
            </v-card-text>
          </v-card>
        </div>
        <div v-else-if="tab.tr_type == 'SPL'">
          <overtime-order :tr_type="tab.tr_type" :itemLists.sync="itemLists" :aprv_level="aprv_level" @displayData="populateAbsenceList($event)"></overtime-order>
        </div>
      </v-tab-item>
    </v-tabs-items>
    <v-dialog
        v-model="dialogCreateRequest"
        persistent
        max-width="600px"
        hide-overlay
        transition="dialog-bottom-transition"
    >
        <v-card class="rounded-l">
            <v-card-title class="text-center mb-0 pb-0">
                <v-row>
                    <v-col cols="12">
                        <div class="d-flex">
                            <div class="ml-5">
                              New Absence Request
                            </div>
                            <v-spacer></v-spacer>
                            <v-btn
                                class="mx-2"
                                fab
                                x-small
                                color="#e83e8c;"
                                @click="[dialogCreateRequest = false]"
                            >
                                <v-icon dark>
                                    mdi-close
                                </v-icon>
                            </v-btn>
                        </div>
                    </v-col>
                    <v-divider></v-divider>
                </v-row>
            </v-card-title>
            <v-card-text class="ma-0">
              <v-container fluid class="ma-0">
                <v-row>
                  <v-col class="col-12" sm="6" md="6">
                    <h6 class="blue-lcd mb-1">Company </h6>
                    <v-autocomplete
                        dense
                        solo
                        clearable
                        v-model="addCompanyIDModel"
                        :items="addCompanyIDList"
                        item-value="Company"
                        item-text="description"
                        class="ma-0 pa-0 border-12"
                        hide-details=true
                        disabled
                    ></v-autocomplete> 
                  </v-col>
                  <v-col class="col-12" sm="6" md="6">
                    <h6 class="blue-lcd mb-1">Reason </h6>
                    <v-autocomplete
                        dense
                        solo
                        clearable
                        v-model="addIjinModel"
                        :items="addIjinList"
                        item-value="str1"
                        item-text="str2"
                        class="ma-0 pa-0 border-12"
                        hide-details=true
                    ></v-autocomplete> 
                  </v-col>
                  <v-col class="col-12" sm="6" md="6">
                    <h6 class="blue-lcd mb-1">Start Date </h6>
                    <v-dialog ref="dialog" v-model="dateStartModal" :return-value.sync="dateStartModel" persistent width="290px" >
                        <template v-slot:activator="{ on, attrs }">
                            <v-text-field solo v-model="dateStartModel" readonly v-bind="attrs" v-on="on" dense clearable append-icon="mdi-calendar-check" class="ma-0 pa-0 border-12"></v-text-field>
                        </template>
                        <v-date-picker v-model="dateStartModel" :show-current="true" @click:date="$refs.dialog.save(dateStartModel)" scrollable>
                            <v-spacer></v-spacer>
                            <v-btn text color="primary" @click="dateStartModal = false">Cancel</v-btn>
                        </v-date-picker>
                    </v-dialog>
                  </v-col>
                  <v-col class="col-12" sm="6" md="6">
                    <h6 class="blue-lcd mb-1">End Date </h6>
                    <v-dialog ref="dialog2" v-model="dateEndModal" :return-value.sync="dateEndModel" persistent width="290px">
                        <template v-slot:activator="{ on, attrs }">
                            <v-text-field solo v-model="dateEndModel" readonly v-bind="attrs" v-on="on" dense clearable append-icon="mdi-calendar-check" class="ma-0 pa-0 border-12"></v-text-field>
                        </template>
                        <v-date-picker v-model="dateEndModel" :show-current="true" @click:date="$refs.dialog2.save(dateEndModel)" scrollable>
                            <v-spacer></v-spacer>
                            <v-btn text color="primary" @click="dateEndModal = false">Cancel</v-btn>
                        </v-date-picker>
                    </v-dialog>
                  </v-col>
                  <v-col class="col-12" sm="12" md="12">
                      <v-textarea solo class="ma-0 pa-0 border-12" v-model="addRemarkModel"
                        hide-details=true counter label="Remark" dense></v-textarea>
                  </v-col>
                  <v-col class="col-12" sm="12" md="12">
                      <!-- <input type="file" name="image" id="image" multiple @change="fileChange" class="" accept="image/*" /> -->
                      <v-file-input solo class="ma-0 pa-0 border-12" v-model="fileUpload" label="Picture file" small-chips counter multiple show-size append-icon="mdi-image" accept="image/*"></v-file-input>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
            <v-card-actions class="text-center">
                <v-row justify="center">
                    <v-col cols="12" xs="12" sm="3" md="3">
                        <v-btn block class="rounded-l mr-2 p-3" color="#fff" @click="[dialogCreateRequest = false]">Cancel</v-btn>
                    </v-col>
                    <v-col cols="12" xs="12" sm="3" md="3">
                        <v-btn block class="rounded-l text-white p-3" color="success" @click="submitAbsenceRequest()">Save</v-btn>
                    </v-col>
                </v-row>
            </v-card-actions>
        </v-card>
    </v-dialog>
    <v-dialog
        v-model="dialogShowImage"
        persistent
        max-width="1000px"
        hide-overlay
        transition="dialog-bottom-transition"
    >
      <v-card class="rounded-l">
        <v-card-title class="text-center mb-0 pb-0">
            <v-row>
                <v-col cols="12">
                    <div class="d-flex">
                        <div class="ml-5">
                          List File
                        </div>
                        <v-spacer></v-spacer>
                        <v-btn
                            class="mx-2"
                            fab
                            x-small
                            color="#e83e8c;"
                            @click="[dialogShowImage = false]"
                        >
                            <v-icon dark>
                                mdi-close
                            </v-icon>
                        </v-btn>
                    </div>
                </v-col>
                <v-divider></v-divider>
            </v-row>
        </v-card-title>
        <v-card-text class="ma-0">
          <v-carousel cycle height="100%">
            <v-carousel-item
              v-for="(item,i) in imageList"
              :key="i"
              :src="item"
              reverse-transition="fade-transition"
              transition="fade-transition"
            ></v-carousel-item>
          </v-carousel>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog
        v-model="dialogfilter"
        persistent
        max-width="600px"
        hide-overlay
        transition="dialog-bottom-transition"
    >
      <v-card class="rounded-l">
        <v-card-title class="text-center mb-0 pb-0">
            <v-row>
                <v-col cols="12">
                    <div class="d-flex">
                        <div class="ml-5">
                          Filter
                        </div>
                        <v-spacer></v-spacer>
                        <v-btn
                            class="mx-2"
                            fab
                            x-small
                            color="#e83e8c;"
                            @click="[dialogfilter = false]"
                        >
                            <v-icon dark>
                                mdi-close
                            </v-icon>
                        </v-btn>
                    </div>
                </v-col>
                <v-divider></v-divider>
            </v-row>
        </v-card-title>
        <v-card-text class="ma-0">
          <v-container fluid class="ma-0">
            <v-row>
              <!-- <v-col class="col-12" sm="6" md="6">
                <h6 class="blue-lcd mb-1">Status Approve</h6>
                <v-autocomplete
                    dense
                    solo
                    clearable
                    v-model="status_aprv"
                    :items="status_aprvs"
                    item-value="value"
                    item-text="text"
                    class="ma-0 pa-0 border-12"
                    hide-details=true
                ></v-autocomplete> 
              </v-col>
              <v-col class="col-12" sm="6" md="6">
                <h6 class="blue-lcd mb-1">Reason </h6>
                <v-autocomplete
                    dense
                    solo
                    clearable
                    v-model="addIjinModel"
                    :items="addIjinList"
                    item-value="str1"
                    item-text="str2"
                    class="ma-0 pa-0 border-12"
                    hide-details=true
                ></v-autocomplete> 
              </v-col> -->
              <v-col class="col-12" sm="6" md="6">
                <h6 class="blue-lcd mb-1">Start Date </h6>
                <v-dialog ref="dialog" v-model="dateStartModal" :return-value.sync="dateStartModel" persistent width="290px" >
                    <template v-slot:activator="{ on, attrs }">
                        <v-text-field solo v-model="dateStartModel" readonly v-bind="attrs" v-on="on" dense clearable append-icon="mdi-calendar-check" class="ma-0 pa-0 border-12"></v-text-field>
                    </template>
                    <v-date-picker v-model="dateStartModel" :show-current="true" @click:date="$refs.dialog.save(dateStartModel)" scrollable>
                        <v-spacer></v-spacer>
                        <v-btn text color="primary" @click="dateStartModal = false">Cancel</v-btn>
                    </v-date-picker>
                </v-dialog>
              </v-col>
              <v-col class="col-12" sm="6" md="6">
                <h6 class="blue-lcd mb-1">End Date </h6>
                <v-dialog ref="dialog2" v-model="dateEndModal" :return-value.sync="dateEndModel" persistent width="290px">
                    <template v-slot:activator="{ on, attrs }">
                        <v-text-field solo v-model="dateEndModel" readonly v-bind="attrs" v-on="on" dense clearable append-icon="mdi-calendar-check" class="ma-0 pa-0 border-12"></v-text-field>
                    </template>
                    <v-date-picker v-model="dateEndModel" :show-current="true" @click:date="$refs.dialog2.save(dateEndModel)" scrollable>
                        <v-spacer></v-spacer>
                        <v-btn text color="primary" @click="dateEndModal = false">Cancel</v-btn>
                    </v-date-picker>
                </v-dialog>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions class="text-center">
          <v-row justify="center">
              <v-col cols="12" xs="12" sm="3" md="3">
                  <v-btn block class="rounded-l mr-2" color="#fff" @click="[dialogfilter = false, clear()]">Cancel</v-btn>
              </v-col>
              <v-col cols="12" xs="12" sm="3" md="3">
                  <v-btn block class="rounded-l text-white" color="#20bf6b" @click="[populateAbsenceList(), dialogfilter = false]">Filter</v-btn>
              </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
        v-model="dialogInfo"
        persistent
        max-width="900px"
        hide-overlay
        transition="dialog-bottom-transition"
    >
      <v-card class="rounded-l" color="#e8ecef">
        <v-card-title class="text-center mb-0 pb-0">
            <v-row>
                <v-col cols="12">
                    <div class="d-flex">
                        <div class="ml-5">
                          <v-icon color="primary" class="mr-3">
                            mdi-account-circle
                          </v-icon> Info Employee
                        </div>
                        <v-spacer></v-spacer>
                        <v-btn
                            class="mx-2"
                            fab
                            x-small
                            color="#e83e8c;"
                            @click="[dialogInfo = false]"
                        >
                            <v-icon dark>
                                mdi-close
                            </v-icon>
                        </v-btn>
                    </div>
                </v-col>
                <v-divider></v-divider>
            </v-row>
        </v-card-title>
        <v-card-text>
          <v-container fluid class="pl-5 pr-5 pt-5 pb-4">
            <v-row>
              <v-col class="col-12" sm="6" md="4">
                <v-card class="rounded-l" outlined min-height="485px" color="#e8ecef">
                  <v-card-text>
                    <v-img :src="avatar" min-height="220px" class="rounded-l"></v-img>
                    <v-btn
                        block
                        color="accent"
                        elevation="2"
                        outlined
                        class="mt-5"
                        @click="changeAvatar()"
                    >
                        Pilih Foto
                    </v-btn>
                    <p class="css-bqy72x mt-5 font-12" style="font-size: 12px;">Besar file: maksimum (2 Megabytes). Ekstensi file yang diperbolehkan: .JPG .JPEG .PNG</p>
                  </v-card-text>
                </v-card>
                <a class="color-primary" @click="$event => dialogPassword = true">Change Password?</a>
              </v-col>
              <v-col class="col-12" sm="6" md="8">
                <v-card class="elevation-1 rounded-l">
                  <v-card-text>
                    <v-list dense two-line class="ma-0 pl-0 pr-0 pt-0 pb-0 mt-3" color="#e8ecef">
                      <v-list-item class="pl-0 pr-0" style="min-height: 40px !important;">
                        <v-list-item-content class="pt-0 pb-0">
                          <v-list-item-title style="font-size: 14px;">{{ $store.state.user.employee.company_name }}</v-list-item-title>
                          <v-list-item-subtitle class="font-12">Company</v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                      <v-list-item class="pl-0 pr-0" style="min-height: 40px !important;">
                        <v-list-item-content class="pt-0 pb-0">
                          <v-list-item-title style="font-size: 14px;">{{ $store.state.user.employee.office_name }}</v-list-item-title>
                          <v-list-item-subtitle class="font-12">Office</v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                      <v-list-item class="pl-0 pr-0" style="min-height: 40px !important;">
                        <v-list-item-content class="pt-0 pb-0">
                          <v-list-item-title style="font-size: 14px;">{{ $store.state.user.employee.dept_name ?  $store.state.user.employee.dept_name : '-' }}</v-list-item-title>
                          <v-list-item-subtitle class="font-12">Department</v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                      <v-list-item class="pl-0 pr-0" style="min-height: 40px !important;">
                        <v-list-item-content class="pt-0 pb-0">
                          <v-list-item-title style="font-size: 14px;">{{ $store.state.user.employee.division_name ? $store.state.user.employee.division_name : '-' }}</v-list-item-title>
                          <v-list-item-subtitle class="font-12">Divisi</v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                      <v-list-item class="pl-0 pr-0" style="min-height: 40px !important;">
                        <v-list-item-content class="pt-0 pb-0">
                          <v-list-item-title style="font-size: 14px;">{{ $store.state.user.employee.sec_name ? $store.state.user.employee.sec_name : '-' }}</v-list-item-title>
                          <v-list-item-subtitle class="font-12">Section</v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                      <v-list-item class="pl-0 pr-0" style="min-height: 40px !important;">
                        <v-list-item-content class="pt-0 pb-0">
                          <v-list-item-title style="font-size: 14px;">{{ $store.state.user.employee.group_name ? $store.state.user.employee.group_name : '-' }}</v-list-item-title>
                          <v-list-item-subtitle class="font-12">Group Section</v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                      <v-list-item class="pl-0 pr-0" style="min-height: 40px !important;">
                        <v-list-item-content class="pt-0 pb-0">
                          <v-list-item-title style="font-size: 14px;">{{ $store.state.user.employee.jabatan ? $store.state.user.employee.jabatan : '-' }}</v-list-item-title>
                          <v-list-item-subtitle class="font-12">Position</v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                      <v-list-item class="pl-0 pr-0" style="min-height: 40px !important;">
                        <v-list-item-content class="pt-0 pb-0">
                          <v-list-item-title style="font-size: 14px;">{{ $store.state.user.employee.emp_stat ? $store.state.user.employee.emp_stat : '-' }}</v-list-item-title>
                          <v-list-item-subtitle class="font-12">Employee Status</v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                      <v-list-item class="pl-0 pr-0" style="min-height: 40px !important;">
                        <v-list-item-content class="pt-0 pb-0">
                          <v-list-item-title style="font-size: 14px;">{{ $store.state.user.employee.emp_type ? $store.state.user.employee.emp_type : '-' }}</v-list-item-title>
                          <v-list-item-subtitle class="font-12">Employee Type</v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                      <v-list-item class="pl-0 pr-0" style="min-height: 40px !important;">
                        <v-list-item-content class="pt-0 pb-0">
                          <v-list-item-title style="font-size: 14px;">{{ $store.state.user.employee.dt_join == '1900-01-01 00:00:00.000' ? '-' : new Date($store.state.user.employee.dt_join).toISOString().substr(0, 10)  }}</v-list-item-title>
                          <v-list-item-subtitle class="font-12">Date Join</v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                      <v-list-item class="pl-0 pr-0" style="min-height: 40px !important;">
                        <v-list-item-content class="pt-0 pb-0">
                          <v-list-item-title style="font-size: 14px;">{{ $store.state.user.employee.dt_resign == '1900-01-01 00:00:00.000' ? '-' : new Date($store.state.user.employee.dt_resign).toISOString().substr(0, 10) }}</v-list-item-title>
                          <v-list-item-subtitle class="font-12">Date Resign</v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list>
                  </v-card-text>
                </v-card>                
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog
        v-model="dialogPassword"
        persistent
        max-width="450px"
        hide-overlay
        transition="dialog-bottom-transition"
    >
      <v-card class="border-12" color="#e8ecef">
        <v-card-title class="text-center mb-0 pb-0">
            <v-row>
                <v-col cols="12">
                    <div class="d-flex">
                        <div class="ml-5">
                          <v-icon color="primary" class="mr-3">
                            mdi-account-circle
                          </v-icon> Change Password
                        </div>
                        <v-spacer></v-spacer>
                        <v-btn
                            class="mx-2"
                            fab
                            x-small
                            color="#e83e8c;"
                            @click="[dialogPassword = false]"
                        >
                            <v-icon dark>
                                mdi-close
                            </v-icon>
                        </v-btn>
                    </div>
                </v-col>
                <v-divider></v-divider>
            </v-row>
        </v-card-title>
        <v-card-text>
          <v-container fluid class="pl-5 pr-5 pt-5 pb-4">
            <v-row>
              <v-col class="col-12" sm="6" md="8">
                <v-card class="rounded-l" outlined>
                  <v-sheet width="350" class="mx-auto p-3">
                    <v-form>
                      <v-text-field
                        solo
                        v-model="newPassword"
                        type="password"
                        required
                        label="New Password"
                        class="border-12"
                      ></v-text-field>
                      <v-text-field
                        solo
                        v-model="confirmPassword"
                        required
                        type="password"
                        label="Confirm New Password"
                        class="border-12"
                      ></v-text-field>
                      <v-btn block class="mt-2 border-12" @click="$event => changePassword()">Submit</v-btn>
                    </v-form>
                  </v-sheet>
                </v-card>                
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="dialog_avatar"
      max-width="500px"
    >
      <v-card class="p-3 rounded-xl">
          <v-card-title class="text-center">
              <v-row align="center" justify="center">
                  <v-col cols="10">
                  <h5 style="font-weight:700; color: #1e4393;">
                  Ubah Foto Profil
                  </h5>
                  </v-col>
              </v-row>  
          </v-card-title>
          <v-card-text>
              <v-container fluid class="fill-height">
                  <v-row>
                      <v-col cols="12" xs="12" sm="12" md="12">
                          <h6>
                              Foto Profil
                          </h6>

                          <div v-if="imgSrc" style="width: auto; height:500px; border: 1px solid gray; display: inline-block;">
                              <vue-cropper
                                  ref='cropper'
                                  :guides="true"
                                  :view-mode="2"
                                  drag-mode="crop"
                                  :auto-crop-area="0.5"
                                  :min-container-width="250"
                                  :min-container-height="180"
                                  :background="true"
                                  :rotatable="true"
                                  :src="imgSrc"
                                  :aspectRatio= "1/1"
                                  :img-style="{ 'width': 'auto', 'height': '500px' }">
                              </vue-cropper>
                          </div>

                          <div v-else>
                              <input type="file" name="image" id="image" @change="setImage" class="form-control" accept="image/*">
                          </div>
                      </v-col>
                  </v-row> 
              </v-container>
          </v-card-text>
          <v-card-actions class="text-center">
              <v-row justify="center">
                  <v-col cols="12" xs="12" sm="3" md="4">
                      <v-btn block class="rounded-l text-white p-4" color="#003871" @click="saveAvatar()">Simpan</v-btn>
                  </v-col>
              </v-row>
          </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import {user} from "@/backend-api/user"
import {overtime} from "@/backend-api/overtime"
import VueCropper from 'vue-cropperjs'
import 'cropperjs/dist/cropper.css'
import OvertimeOrder from "@/components/OvertimeOrder.vue"
import Swal from 'sweetalert2'

export default {
  components:{
    VueCropper, OvertimeOrder
  },
  data: () => ({
    headers: [
        { text: 'DATE', value: 'tr_date', align: 'left', class: 'primary--text blue lighten-5', width:120 },
        { text: 'REASON', value: 'ijin_description', align: 'left', class: 'primary--text blue lighten-5', width:120 },
        { text: 'ATTACHMENT', value: 'file_name', align: 'center', class: 'primary--text blue lighten-5', width:150 },
        { text: 'REMARK', value: 'keterangan', align: 'left', class: 'primary--text blue lighten-5', width:300 },
        { text: 'START DATE', value: 'dt_from', align: 'left', class: 'primary--text blue lighten-5', width:120 },
        { text: 'UNTIL DATE', value: 'dt_to', align: 'left', class: 'primary--text blue lighten-5', width:120 },
        { text: 'STAT APRV', value: 'aprv', align: 'left', class: 'primary--text blue lighten-5', width:120 },
        // { text: '', value: 'stat', align: 'center', class: 'primary--text blue lighten-5' },        
        // { text: '', value: 'actions', align: 'center', class: 'primary--text blue lighten-5' },
    ],
    dialogInfo: false,
    dialogPassword: false,
    confirmPassword: '',
    newPassword : '',
    headersColumn:{
      'DATE': 'tr_date',
      'REASON': 'ijin_descr',
      'REMARK': 'keterangan',
      'START DATE': 'dt_from',
      'UNTIL DATE': 'dt_to',
      'Stat': 'stat',
    },
    itemLists: [],
    searchItem: '',
    dialogCreateRequest: false,
    addIjinModel: '',
    dateStartModel: '',
    dateEndModel: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
    addRemarkModel: '',
    fileUpload: [],
    addCompanyIDModel: '',
    addCompanyIDList: [],
    addIjinModel: '',
    addIjinList: [],
    addIjinLoading: false,
    dateStartModal: false,
    dateStartFormatted: '',
    dateEndModal: false,
    dateEndFormatted: '',
    addRemarkModel: '',
    // imageModel: '',
    imageModel: [],
    dialogShowImage: false,
    imageList: [],
    dialogfilter: false,
    status_aprv: '',
    status_aprvs: [
      {
        text: 'Disetujui',
        value: 'disetujui'
      },
      {
        text: 'Belum Disetujui',
        value: 'belum_disetujui'
      }
    ],
    max_aprv: 1,
    foto_profile: '',
    dialog_avatar: false,
    imgSrc: '',
    currentTabIndex: 'tab-1',
    tabs:[
      {
        aprv_num: 1,
        descr: 'Absence Permit',
        tr_type: 'IMP'
      },
      {
        aprv_num: 2,
        descr: 'Overtime Permit',
        tr_type: 'SPL'
      }
    ],
    annual_leave: 0,
    leave_usage: 0,
    remaining_days_off: 0,
    aprv_level: ''
  }),
  methods:{
    tr_datatable(item) {
        var rowClass = 'tr_datatable'
        return rowClass;
    },
    clear(){
      this.addIjinModel = ''
      this.addRemarkModel = ''
      this.dateStartModel = ''
      this.dateEndModel = ''
      this.addIjinModel = ''
      this.fileUpload = []
    },
    async initialize(){
      const respData = await user.fetchCompany(`?id=${this.$store.state.user.id}`, null, false, false, false) 
      if (respData.status === 200) {
        this.addCompanyIDModel = this.$store.state.user.employee.company_id
        this.addCompanyIDList = respData.data.entity
        this.addIjinList = respData.data.ijin
      }
    },
    async maxApprove(){
      var reqBody = {
        company: this.$store.state.user.employee.company_id.trim(),
        tr_type: 'IMP',
        dep_id: this.$store.state.user.employee.dept_id ? this.$store.state.user.employee.dept_id.trim() : '',
        division_id: this.$store.state.user.employee.division_id ? this.$store.state.user.employee.division_id.trim() : ''
      }

      const respData = await user.maxApprove('', reqBody, false, false, false)

      if (respData.status === 200) {
        console.log(respData.data)
        this.max_aprv = respData.data.aprv_num ? Number(respData.data.aprv_num) : 1
        // console.log(this.max_aprv);
      }
    },
    async populateAbsenceList(tr_type){
      this.itemLists = []
      this.$store.dispatch('setOverlay', true)

      var reqBody = {
        id: this.$store.state.user.id,
        company: this.$store.state.user.employee.company_id,
        ijin: this.addIjinModel ? this.addIjinModel : '',
        startDate: this.dateStartModel ? new Date(this.dateStartModel).toISOString().substr(0, 10).replace(/-/g,"") : '',
        endDate: this.dateEndModel ? new Date(this.dateEndModel).toISOString().substr(0, 10).replace(/-/g,"") : '',
        status_aprv: this.status_aprv ? this.status_aprv : '',
        aprv_num: this.max_aprv ? this.max_aprv : 1,
        tr_type: tr_type ? tr_type : 'IMP',
        aprv_level: this.aprv_level ? this.aprv_level : ''
      }

      if(reqBody.tr_type == 'SPL'){
        reqBody.dept_id = this.$store.state.user.employee.dept_id ? this.$store.state.user.employee.dept_id.trim() : '',
        reqBody.division_id = this.$store.state.user.employee.division_id ? this.$store.state.user.employee.division_id.trim() : ''
        reqBody.section_id = this.$store.state.user.employee.section_id ? this.$store.state.user.employee.section_id.trim() : ''
        reqBody.group_id = this.$store.state.user.employee.group_id ? this.$store.state.user.employee.group_id.trim() : ''

        const respData = await overtime.index('', reqBody, false, false, false)

        if (respData.status === 200) {
          this.itemLists = respData.data.result
          this.$store.dispatch('setOverlay', false)
        }
      } else {
        const respData = await user.index('', reqBody, false, false, false)

        if (respData.status === 200) {
          this.itemLists = respData.data.result
          this.$store.dispatch('setOverlay', false)
        }
      }

      
    },
    async showImage(item){
      this.$store.dispatch('setOverlay', true)
      this.imageList = []

      var reqBody = {
        'id': item.doc_id,
        'idemployee': item.idemployee,
        'company_id': item.company_id,
      }

      const respData = await user.getImages('', reqBody, false, false, false)

      if (respData.status === 200) {
        this.dialogShowImage = true
        for (let index = 0; index < respData.data.length; index++) {
            const element = respData.data[index];
            this.imageList.push(process.env.VUE_APP_URL + '/img/hrd/emp/izin/' + element.file_name)
        }
        this.$store.dispatch('setOverlay', false)
      }
    },
    rowDocument(item){

    },
    async deleteAbsence(id){
      Swal.fire({
          text: 'Do you want Delete this Absence Request ?',
          showDenyButton: true,
          confirmButtonText: 'Yes',
          confirmButtonColor: 'green',
          denyButtonText: 'Cancel',
          denyButtonColor: 'red'
          }).then((result) => {
            console.log('state', this.$store.state)
          if (result.isConfirmed) {
              
              axios.post(`${process.env.VUE_APP_URL}/api/AbsensePermit/deleteAbsence`, {

                  id: id,
                  id_employee: this.$store.state.user.id,
                  tr_type: 'IMP',
                  company_id: this.$store.state.user.company_id
              },
              { 
                  headers: { 
                      'Accept': 'application/json',
                      'Content-Type': 'application/json',
                      'Access-Control-Allow-Origin': '*',
                      Authorization: `Bearer ${this.$store.getters.isTokenUser}`
                  } 
              })
              .then(res => {

                  this.populateAbsenceList()
                  
              })
              .catch(err => {
                  
                  console.log(err);

              })

          }
      })
    },
    showDialogAbsenceRequest(){
      this.dialogCreateRequest = true
      this.addIjinModel = ''
      this.dateStartModel = ''
      this.dateEndModel = ''
      this.addRemarkModel = ''
      this.fileUpload = []
    },
    currentDate() {
      const current = new Date();
      const date = `${current.getFullYear()}-${current.getMonth()+1}-${current.getDate()}`;      
      return date;
    },
    async exportExcel(){
      var reqBody = {
        id: this.$store.state.user.id,
        company: this.$store.state.user.employee.company_id,
        ijin: this.addIjinModel ? this.addIjinModel : '',
        startDate: this.dateStartModel ? new Date(this.dateStartModel).toISOString().substr(0, 10).replace(/-/g,"") : '',
        endDate: this.dateEndModel ? new Date(this.dateEndModel).toISOString().substr(0, 10).replace(/-/g,"") : '',
        status_aprv: this.status_aprv ? this.status_aprv : '',
        aprv_num: this.max_aprv ? this.max_aprv : 1,
        tr_type: 'IMP'
      }

      const respData = await user.index('', reqBody, false, false, false)

      if (respData.status === 200) {
        return respData.data.result
      }
    },
    startDownload(){
        this.$store.dispatch('setOverlay', true)
    },
    finishDownload(){
        this.$store.dispatch('setOverlay', false)
    },
    datediff(first, second) {        
        return Math.round((second - first) / (1000 * 60 * 60 * 24));
    },
    parseDate(date) {
        var formattedDate = date.split("-")
        return new Date(formattedDate[0], formattedDate[1], formattedDate[2])
    },
    async submitAbsenceRequest(){

      if(this.addIjinModel == null || this.addIjinModel == ''){
          Swal.fire({
                  text: 'Please Select Absence Reason !',
                  icon: 'warning',
              })
          return false
      }

      if(this.addRemarkModel == null || this.addRemarkModel == ''){

          Swal.fire({
                  text: 'Please Fill Absence Remark !',
                  icon: 'warning',
              })
          return false

      }

      if (this.dateStartModel) {
          this.dateStartFormatted = new Date(this.dateStartModel).toISOString().substr(0, 10).replace(/-/g,"")
      } else  {
          this.dateStartFormatted = ''
      }

      if (this.dateEndModel) {
          this.dateEndFormatted = new Date(this.dateEndModel).toISOString().substr(0, 10).replace(/-/g,"")
      } else  {
          this.dateEndFormatted = ''
      }

      if(this.fileUpload.length == 0){
          this.imageExist = null
      } else {
          this.imageExist = 'Y'
      }

      if (this.dateStartFormatted && this.dateEndFormatted) {
        var start = (new Date(new Date(this.dateStartModel) - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)
        var end = (new Date(new Date(this.dateEndModel) - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10) 

        var interval = this.datediff((new Date(new Date(this.dateStartModel) - (new Date()).getTimezoneOffset() * 60000)), (new Date(new Date(this.dateEndModel) - (new Date()).getTimezoneOffset() * 60000)))

        if (interval < 0) {
          Swal.fire({
              text: 'Please Check Start and End Date !',
              icon: 'warning',
          })
          return false
        }
      }
      
      this.dialogCreateRequest = false
      this.$store.dispatch('setOverlay', true)

      let formData = new FormData()
      formData.append('idemployee', this.$store.state.user.id)
      formData.append('company', this.$store.state.user.employee.company_id)
      formData.append('ijin', this.addIjinModel ? this.addIjinModel : '')
      formData.append('startDate', start)
      formData.append('endDate', end)
      formData.append('remark', this.addRemarkModel ? this.addRemarkModel : '')
      formData.append('image', this.imageExist)
      formData.append('dept_id', this.$store.state.user.employee.dept_id ? this.$store.state.user.employee.dept_id.trim() : '')

      for( var i = 0; i < this.fileUpload.length; i++ ){
          let file = this.fileUpload[i];
          formData.append('file[' + i + ']', file)
      }

      await axios.post(`${process.env.VUE_APP_URL}/api/AbsensePermit/submitData`, formData, { 
          headers: { 
              'Content-Type': 'multipart/form-data',
              'Access-Control-Allow-Origin': '*',
          } 
      }).then(res => {
        this.clear()
        this.populateAbsenceList()
        this.$store.dispatch('setOverlay', false)
        this.fileUpload = []
      }).catch(err => {
        this.dialogCreateRequest = true
        this.$store.dispatch('setOverlay', false)
      })

    },
    async uploadFile(id){

      let formData = new FormData()
      formData.append('employee_id', this.$store.state.user.id)
      formData.append('doc_id', id)

      for( var i = 0; i < this.fileUpload.length; i++ ){
          let file = this.fileUpload[i];
          formData.append('file[' + i + ']', file)
      }

      await axios.post(`${process.env.VUE_APP_URL}/api/AbsensePermit/uploadFile`, formData, { 
          headers: { 
              'Content-Type': 'multipart/form-data',
              'Access-Control-Allow-Origin': '*',
          } 
      }).then(res => {
          this.fileUpload = []
      }).catch(err => {
          console.log(err)
      })


    },
    changeAvatar(){
      this.dialog_avatar = true
    },
    reset() {
            this.imgSrc = ''
            this.cropImg = ''
		},
    setImage(e) {
			this.reset()
			const file = e.target.files[0]
			if (!file.type.includes('image/')) {
				alert('Please select an image file');
				return;
			}
			if (typeof FileReader === 'function') {
				const reader = new FileReader()
				reader.onload = (event) => {
					this.imgSrc = event.target.result
				};
				reader.readAsDataURL(file)
			} else {
				alert('Sorry, FileReader API not supported')
			}
		},
    async getPullAvatar(){
      var reqBody = {
        'office_id': this.$store.state.user.employee.office_id,
        'company_id': this.$store.state.user.employee.company_id,
        'idemployee': this.$store.state.user.id
      }

      const respData = await user.getAvatar('', reqBody, false, false, false)

      if (respData.status === 200) {
        this.foto_profile = respData.data.data.avatar
      }
    },
    async saveAvatar(){

      this.cropImg = this.imgSrc ? this.$refs.cropper.getCroppedCanvas().toDataURL() : ''

      var reqBody = {
        'office_id': this.$store.state.user.employee.office_id,
        'company_id': this.$store.state.user.employee.company_id,
        'idemployee': this.$store.state.user.id,
        'origin': this.imgSrc,
        'thumbnail': this.cropImg
      }

      const respData = await user.updateAvatar('', reqBody, false, false, false)

      if (respData.status === 200) {
          this.dialog_avatar = false
          this.imgSrc = ''
          this.cropImg = ''
          // this.getPullAvatar()
          this.$store.dispatch('getHeaderAvatar')
          this.snackbar = {
              color: "success",
              icon: "mdi-checkbox-marked-circle",
              mode: "multi-line",
              position: "top",
              timeout: 7500,
              title: "Success",
              text: "Successfully Update Photo Profile",
              visible: true
          };
      } else {
          this.snackbar = {
              color: "error",
              icon: "mdi-alert-circle",
              mode: "multi-line",
              position: "top",
              timeout: 7500,
              title: "Error",
              text: 'Internal Server Error' + respData.status,
              visible: true
          };
      }
    },
    async changePassword () {
      try {
        if(this.newPassword == this.confirmPassword){
          var reqBody = {
            'idemployee': this.$store.state.user.id,
            'password': this.newPassword
          }
          
          this.dialogInfo = false
          this.dialogPassword = false
          this.$store.dispatch('setOverlay', true)
          const response = await user.chengePassword('',reqBody)
          this.$store.dispatch('setOverlay', false)
          
          
          if(response.status === 200){
            Swal.fire({
              icon: 'success',
              title: 'Berhasil!',
              text: 'Password Berhasil di Ganti!'
            })
            this.newPassword = ''
            this.confirmPassword= ''
          }else{
            Swal.fire({
              icon: 'error',
              title: 'Gagal!',
              text: 'Password Gagal di Ganti!'
            })
            this.dialogInfo = true
            this.dialogPassword = true
          }
        }else{
            Swal.fire({
              icon: 'error',
              title: 'Password Gagal di Ganti!',
              text: 'Confirm Password dan New Password harus sama!'
            })
          }
      } catch (error) {
        console.log(error);
        Swal.fire({
          icon: 'error',
          title: 'Gagal Ganti Password!!',
          text: error,
        })
      }
    },
    async getDashboard(){
      var reqBody = {
        company_id: this.$store.state.user.employee.company_id.trim(),
        idemployee: this.$store.state.user.id,
        year: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 4)
      }

      const respData = await user.dashboard('', reqBody, false, false, false)

      if (respData.status === 200) {
        this.annual_leave = respData.data.data.annual_leave
        this.leave_usage = respData.data.data.leave_usage
        this.remaining_days_off = respData.data.data.remaining_days_off
      }
    },
    async checkUserAprvSpl(){
      var reqBodySPL = {
        company: this.$store.state.user.employee.company_id.trim(),
        idemployee: this.$store.state.user.id,
        tr_type: 'SPL'
      }
      const respDataSPL = await user.cekUserApprove('', reqBodySPL, false, false, false)
      console.log(respDataSPL);
      if (respDataSPL.status === 200) {
        this.aprv_level = respDataSPL.data.length > 0 ? respDataSPL.data[0].aprv_level : ''
      }
    }
  },
  computed: mapState([
    'avatar'
  ]),
  async mounted() { 
    this.$store.dispatch('setOverlay', true)
    var d = new Date((new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10));
    d.setMonth(d.getMonth() - 3);
    this.dateStartModel = (new Date(d - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)

    await this.maxApprove()
    await this.checkUserAprvSpl()
    await this.initialize()
    await this.populateAbsenceList('IMP')
    await this.getDashboard()
    this.$store.dispatch('setOverlay', false)
  },
  watch: {
  }
}
</script>
  
<style scoped>
.tr_datatable{
  background-color: #F5F7F8 !important;
}
  
</style>